import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';

@Component({
  selector: 'app-go-back-header',
  templateUrl: './go-back-header.component.html',
  styleUrls: ['./go-back-header.component.scss'],
})
export class GoBackHeaderComponent implements OnInit {
  
  constructor(private navigation: NavController, private router: IonRouterOutlet) { }

  ngOnInit() {}


  goBack() {
    if(this.router.canGoBack()) {
      this.navigation.back()
    }else {
      this.navigation.navigateRoot('/tabs/home')
    }
  }

}
