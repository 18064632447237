export const DATETIMEFORMART = 'YYYY-MM-DDTHH:mm';

export interface EventRequest {
    placeEmailAddress?: string;
    subject: string;
    startTime: string;
    endTime: string;
    reminderMinutesBeforeStart?: number | null;
    attendees?: AttendeeInformation[];
}

export interface CalendarEvent {
    id: string;
    startTime: string;
    endTime: string;
    subject: string;
    organizer: string;
    reminderMinutesBeforeStart?: number | null
    place: {
        address: {
            street: string;
            zip: string;
            city: string;
            floor?: string;
        };
        displayName: string;
        type: string;
        emailAdress?: string;
    };
    resourceStatus:string;
    attendees: AttendeeInformation[];
}

export interface Place {
    type: string;
    displayName: string;
    avatar: any;
    isAvailable: boolean;
    capacity?: string;
    availableSpace?: string;
    emailAdress: string;
    isFav:boolean;
    address: {
        street: string;
        zip: string;
        city: string;
        floor?: string;
    };
}

export interface SearchRequest {
    startTimeUTC: string;
    endTimeUTC: string;
    type?: string;
    localStartTimeValue?: any;
    localEndTimeValue?: any;
}

export interface UserInformation {
    avatar: string;
    displayName: string;
    jobTitle: string;
    department: string;
    companyName: string;
    surname: string;
    givenName: string;
    mobilePhone: string;
    userPrincipalName: string;

}

export interface AttendeeInformation{
    firstName: string;
    lastName: string;
    userPrincipalName: string;
    isOptional?: boolean;
    isAvailable?: boolean;
    response: string; 

}

// export interface AttendeeResponseInformation{
//     displayName: string;
//     userPrincipalName: string;
//     isOptional?: boolean;
//     response: string; 
// }


export interface BookingPeriod{
    startTime: string,
    selectedStartTime: string,
    endTime: string,
    selectedEndTime: string,
    startTimeMinutes: number,
    endTimeMinutes: number,
    selectedTimesRange:any
}

/*export interface AccountContactTicket {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    language: string
}*/

export const reminderMinutes = [
    {text: 'reminderSelect.without', value: null},
    {text: 'reminderSelect.atEventStart', value: 0},
    {text: 'reminderSelect.fiveMinutes', value: 5},
    {text: 'reminderSelect.tenMinutes', value: 10},
    {text: 'reminderSelect.fithteenMinutes', value: 15},
    {text: 'reminderSelect.thirtyMinutes', value: 30},
    {text: 'reminderSelect.oneHour', value: 60},
    {text: 'reminderSelect.twoHours', value: 120},
    {text: 'reminderSelect.oneDay', value: 1440},
    {text: 'reminderSelect.oneWeek', value: 241920},    
]
