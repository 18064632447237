import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { finalize, Subscription, take } from 'rxjs';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { AttendeeInformation, SearchRequest } from 'src/app/interfaces/interfaces';
import { AllPeopleService } from 'src/app/services/all-people-service/all-people.service';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SearchService } from 'src/app/shared/search/search.service';

@Component({
  selector: 'app-attendees-modal',
  templateUrl: './attendees-modal.component.html',
  styleUrls: ['./attendees-modal.component.scss'],
})
export class AttendeesModalComponent implements OnInit, OnDestroy {
  @ViewChild('attendeeInput') attendeeeInput: IonInput;
  getAttendeeSubscripction: Subscription
  filterTerm: string;
  attendeesForFiltering: AttendeeInformation[] = [];
  allAttendeesFromApi: AttendeeInformation[] = [];
  choosenAttendeesToAdd: AttendeeInformation[] = [];
  finalChoosenAttendees: AttendeeInformation[] = [];
  addingAttendde = false;
  acceptedAttendees: AttendeeInformation[] = [];
  declinedAttendees: AttendeeInformation[] = [];
  pendingAttendees: AttendeeInformation[] = [];
  bookingType:string;
  searchRequest:SearchRequest;

  constructor(private apiService: ApiService, private modalController: ModalController, private searchService: SearchService, private allPeopleService: AllPeopleService) { }


  ngOnInit() { 
    this.splitAttendessByResponse();
  
    
    this.addingAttendde = true;

    this.allAttendeesFromApi = this.allPeopleService.getPersonList();

    this.allAttendeesFromApi = this.allAttendeesFromApi.filter(item => {
      return  !this.finalChoosenAttendees.find( element => {
        return element.userPrincipalName == item.userPrincipalName
      })
    });
    this.addingAttendde = false;
  }


  checkforChoosenAttendees() {
    this.allAttendeesFromApi.filter(attendee => {
      if(this.choosenAttendeesToAdd.find(a => a.userPrincipalName == attendee.userPrincipalName)) {
        let i = this.allAttendeesFromApi.indexOf(attendee)
        this.allAttendeesFromApi.splice(i, 1)
      }
    })
  }


  filterAttendees(event) {
    if (event.target.value == "") {
      this.attendeesForFiltering = [];
      return;
    };
    console.log(this.allAttendeesFromApi);
    
    const query = event.target.value.toLowerCase();
    this.attendeesForFiltering = this.allAttendeesFromApi.filter(attendee =>
      attendee.firstName.toLowerCase().indexOf(query) > -1 ||
      attendee.lastName.toLowerCase().indexOf(query) > -1 ||
      attendee.userPrincipalName.toLowerCase().indexOf(query) > -1)
  }

  addAttendee(attendee: AttendeeInformation) {
    this.addingAttendde = true;
    this.checkAttendeAvailability(attendee.userPrincipalName).subscribe(isAvailable => {
      attendee.isAvailable = isAvailable;
      
      let attendeesForFilteringIndex = this.attendeesForFiltering.indexOf(attendee);
      this.attendeesForFiltering.splice(attendeesForFilteringIndex,1);
      let index = this.allAttendeesFromApi.indexOf(attendee);
      this.allAttendeesFromApi.splice(index, 1)
      this.attendeeeInput.value = "";
      attendee.response="";
      this.finalChoosenAttendees.push(attendee);
      this.splitAttendessByResponse();
    });
  }

  removeAttendee(attendee: AttendeeInformation) {
    let indexfinalChoosenAttendees = this.finalChoosenAttendees.indexOf(attendee);
    this.finalChoosenAttendees.splice(indexfinalChoosenAttendees, 1);
    this.allAttendeesFromApi.push(attendee);
    this.splitAttendessByResponse();
  }

  makeOptional(attendee: AttendeeInformation) {
    attendee.isOptional = true
    let index = this.finalChoosenAttendees.indexOf(attendee)
    this.finalChoosenAttendees.splice(index, 1)
    this.finalChoosenAttendees.push(attendee);
    this.splitAttendessByResponse();
  }

  checkAttendeAvailability(userPrincipalName: string) {
    if(this.bookingType == EVENTPAGETYPES.scheduledBooking){

      return this.apiService.checkAttendeeAvailability(
        userPrincipalName,
        this.searchService.searchState$.getValue().searchRequest.startTimeUTC,
        this.searchService.searchState$.getValue().searchRequest.endTimeUTC).pipe(finalize(()=> this.addingAttendde = false))
      } else if (this.bookingType == EVENTPAGETYPES.editBooking) {

        return this.apiService.checkAttendeeAvailability(
          userPrincipalName,
          this.searchRequest.startTimeUTC,
          this.searchRequest.endTimeUTC).pipe(finalize(()=> this.addingAttendde = false))
      }
  }

  dismissModal(action: boolean) {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.getAttendeeSubscripction?.unsubscribe();
  }

  splitAttendessByResponse (){
    this.acceptedAttendees = this.finalChoosenAttendees.filter(item=>{
      return item.response.toLowerCase() == "accepted"
    })
    this.declinedAttendees = this.finalChoosenAttendees.filter(item=>{
      return item.response.toLowerCase() == "declined"
    })
    this.pendingAttendees = this.finalChoosenAttendees.filter(item=>{
      return item.response.toLowerCase() == "none" || item.response.toLowerCase() == "tentativelyaccepted" || item.response.toLowerCase() == "notresponded";
    })
    this.choosenAttendeesToAdd = this.finalChoosenAttendees.filter(item=>{
      return item.response == ""
    })
  }

}
