import {AfterViewInit,ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import { EVENTPAGETYPES, EventPage} from 'src/app/event/event.page';
import { SearchService } from '../search/search.service';
import { DATETIMEFORMART, SearchRequest } from 'src/app/interfaces/interfaces';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { ViewWillEnter } from '@ionic/angular';
import { ApiService } from 'src/app/services/api-services/api.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-date-time-select',
  templateUrl: './date-time-select.component.html',
  styleUrls: ['./date-time-select.component.scss'],
})
export class DateTimeSelectComponent implements  OnInit, AfterViewInit {
  @Input() searchRequest: SearchRequest
  @Input() bookingType: string
  @Input() placeEmailAddress: string
  @Output() updateSearchRequestEmitter = new EventEmitter<SearchRequest>();
  @Output() updateIsQuickBooking= new EventEmitter<Boolean>();
  public durationShow;
  public localStartTimeValue: string;
  public localEndTimeValue: string;
  public minimalDate: string;
  public startDate: string;
  public isRestofDay=false;
  public isEndTimeDisabled: boolean=false;
  public isQuickBooking:boolean=false
  public originalEndTimeValueFromSearchRequest: string;
  constructor(public searchService: SearchService, public translate: LanguageService, private changeDetectorRef: ChangeDetectorRef, private apiService: ApiService) { 
  }
  ngOnInit() {
    this.prepareSearchRequest();
    this.localStartTimeValue = this.searchRequest.localStartTimeValue;
    this.calculateDuration(this.searchRequest.localStartTimeValue, this.searchRequest.localEndTimeValue);
    if(!this.isQuickBooking || this.bookingType == EVENTPAGETYPES.scheduledBooking) {
      return
    } else {
      if( this.bookingType == EVENTPAGETYPES.editBooking && this.isQuickBooking) {
        this.apiService.checkPlaceAvailability(this.placeEmailAddress, moment(this.searchRequest.endTimeUTC).add(1, "minutes").utc().format(DATETIMEFORMART), moment().endOf("day").utc().format(DATETIMEFORMART)).pipe(
          map(res => {
            this.isRestofDay = !res;
          })
        ).subscribe();
      } else {
        this.apiService.checkPlaceAvailability(this.placeEmailAddress, this.searchRequest.startTimeUTC, moment().endOf("day").utc().format(DATETIMEFORMART)).pipe(
          map(res => {
            this.isRestofDay = !res;
          })
          ).subscribe();
        }
    }
  }

  toggleEndOfTheDayBooking(event) {
    if (event.detail.checked) {
      this.originalEndTimeValueFromSearchRequest = this.searchRequest.localEndTimeValue; 
      this.isEndTimeDisabled = true;
      this.searchRequest.endTimeUTC = moment(this.searchRequest.localEndTimeValue).endOf("day").utc().format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(this.searchRequest.localEndTimeValue).endOf("day").format(DATETIMEFORMART);
      this.localEndTimeValue = this.searchRequest.localEndTimeValue;
      this.changeDetectorRef.detectChanges();
    } else {
      this.localEndTimeValue = this.originalEndTimeValueFromSearchRequest;
      this.searchRequest.endTimeUTC = moment(this.localEndTimeValue).utc().format(DATETIMEFORMART)
      this.searchRequest.localEndTimeValue = this.localEndTimeValue; 
      this.isEndTimeDisabled = false;
    }
    this.calculateDuration(this.searchRequest.localStartTimeValue, this.searchRequest.localEndTimeValue);
  }

  private checkIfQuickBooking (startTime) {
    let now = moment().add(1, "minutes").format(DATETIMEFORMART)
    this.isQuickBooking = moment(startTime).isBefore(now)
    this.updateIsQuickBooking.emit(this.isQuickBooking);
  }

  private prepareSearchRequest() {    
    if (this.bookingType == EVENTPAGETYPES.quickBooking) {
      this.searchRequest = {
        startTimeUTC: moment().utc().format(DATETIMEFORMART),
        endTimeUTC: null,
        localStartTimeValue: moment().format(DATETIMEFORMART),
        localEndTimeValue: null,
        type: null
      };     
    } else{
      this.startDate = moment(this.searchRequest.localStartTimeValue).format('YYYY-MM-DD')
    }
    this.minimalDate = moment().format('YYYY-MM-DD')
    this.setLocalEndTimeValue();
    this.calculateDuration(this.searchRequest.localStartTimeValue, this.searchRequest.localEndTimeValue);  
    this.checkIfQuickBooking(this.searchRequest.localStartTimeValue)
  }

  ngAfterViewInit(): void {
    this.searchService.invalidBookingRequest$.next(false);
  }

  private setLocalEndTimeValue() {
    let roundedToNextFiveMinute = this.roundUpMinutes(this.searchRequest.localStartTimeValue);
    if(this.bookingType == EVENTPAGETYPES.quickBooking) {
      this.localEndTimeValue = moment(this.searchRequest.localStartTimeValue).add(roundedToNextFiveMinute, "minutes").format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = this.localEndTimeValue
      this.searchRequest.endTimeUTC = moment(this.localEndTimeValue).utc().format(DATETIMEFORMART)
    } else {
      this.localEndTimeValue = this.searchRequest.localEndTimeValue;
    }
    
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
  }

  private roundUpMinutes(startTime: string) {
    let roundedToNextFiveMinute = 0;
    if (moment(startTime).minute() % 5 == 0) {
      roundedToNextFiveMinute = 5 - (moment(startTime).minute() % 5);
    } else {
      roundedToNextFiveMinute = 10 - (moment(startTime).minute() % 5);
    }
    return roundedToNextFiveMinute;
  }

  private calculateDuration(startTime: string, endTime: string) {
    this.durationShow = null;
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));    
    const hours = duration.hours().toFixed().padStart(2, '0');
    const min = (duration.asMinutes() % 60).toString().padStart(2, '0');
    this.durationShow = hours + ':' + min;
  }

  dateChange(event) {
    const combinedStart = this.mapNewDateAndTime(event , this.searchRequest.localStartTimeValue);
    const combinedEnd = this.mapNewDateAndTime(event, this.searchRequest.localEndTimeValue);
    
    this.searchRequest.startTimeUTC = moment(combinedStart).utc().format(DATETIMEFORMART)
    this.searchRequest.endTimeUTC = moment(combinedEnd).utc().format(DATETIMEFORMART)
    this.searchRequest.localStartTimeValue = combinedStart;
    this.searchRequest.localEndTimeValue = combinedEnd;
    this.localStartTimeValue = combinedStart;
    this.localEndTimeValue = combinedEnd;
    
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }    
  } 

  private mapNewDateAndTime(event: any, time: string) {
    const newDate = moment(event.target.value).format('YYYY-MM-DD');
    const newTime = moment(time).format('HH:mm');
    const combined = moment(newDate).add(newTime).format(DATETIMEFORMART);
    return combined;
  }


  startTimeChange(event) {
    this.searchRequest.startTimeUTC = moment(event.target.value).utc().format(DATETIMEFORMART);
    this.searchRequest.localStartTimeValue = moment(event.target.value).format(DATETIMEFORMART);
    this.localStartTimeValue = moment(event.target.value).format(DATETIMEFORMART);

    if (moment(this.localStartTimeValue).valueOf() > moment(this.localEndTimeValue).valueOf()) {
      this.searchRequest.endTimeUTC = moment(this.localStartTimeValue).add(30, 'minutes').format(DATETIMEFORMART)
      this.searchRequest.localEndTimeValue = moment(this.localStartTimeValue).add(30, 'minutes').format(DATETIMEFORMART)
      this.localEndTimeValue = moment(this.localStartTimeValue).add(30, 'minutes').format(DATETIMEFORMART)
    } else {
      this.searchService.isBookable$.next(true);
    }
    
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
    this.calculateDuration(this.localStartTimeValue, this.localEndTimeValue);    
  }

  public endTimeChange(event) {
    const temp = moment(event.target.value).format(DATETIMEFORMART);    
    if(moment(this.searchRequest.localStartTimeValue).valueOf() > moment(temp).subtract(5, 'minutes').valueOf()) {
      this.searchService.invalidBookingRequest$.next(true);
      this.searchService.isBookable$.next(true);
      this.calculateDuration(this.searchRequest.localStartTimeValue, temp);   
      this.localEndTimeValue = moment(event.target.value).format(DATETIMEFORMART);
      return;
    } else {
      this.searchService.invalidBookingRequest$.next(false);
      this.searchRequest.endTimeUTC = moment(event.target.value).utc().format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(event.target.value).format(DATETIMEFORMART);
      this.localEndTimeValue = moment(event.target.value).format(DATETIMEFORMART);
    }
    
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
    this.calculateDuration(this.localStartTimeValue, this.localEndTimeValue);    
  }

}
