/* eslint-disable @typescript-eslint/member-ordering */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { catchError, finalize, map, of, switchMap, take } from 'rxjs';
import { SearchService } from '../shared/search/search.service';
import { ApiService } from '../services/api-services/api.service';
import { EventRequest, CalendarEvent, SearchRequest, reminderMinutes, AttendeeInformation } from '../interfaces/interfaces';
import { LanguageService } from '../services/languageservice/language.service';
import * as _ from 'lodash';
import { AttendeesModalComponent } from '../modals/attendees-modal/attendees-modal.component';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Browser, OpenOptions } from '@capacitor/browser';
export const EVENTPAGETYPES = {
  quickBooking: 'quickBooking',
  scheduledBooking: 'scheduledBooking',
  editBooking: 'editBooking'
};




@Component({
  selector: 'app-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
})

export class EventPage implements OnInit, OnDestroy {
  place: any;
  subject = null;
  notCreated = false;
  searchRequest: SearchRequest;
  eventSubmitted = false;
  title: string;
  bookingType: string;
  placeEmailAdress;
  locale;
  isEdited: boolean = false;
  reminutesToSelect = reminderMinutes;
  reminderMinutesBeforeStart: number = 15;
  attendees: AttendeeInformation[] = [];
  isAutoGrow = false;
  isQuickBooking;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private translateService: TranslateService,
    private languageService: LanguageService,
    public searchService: SearchService,
    public actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private platform: Platform,
    private oidcSecurityService: OidcSecurityService,) {

    this.locale = this.languageService.selectedLanguage$.getValue()?.value;

    if (this.route.snapshot.data.id) {
      this.place = this.route.snapshot.data.id
      this.placeEmailAdress = this.place.emailAddress
      this.bookingType = EVENTPAGETYPES.quickBooking
    } else {
      this.initateStateAndObject$().pipe(take(1)).subscribe();
    }

  }


  ngOnInit() {
    this.initDateTimeSelect();
  }

  private initateStateAndObject$() {
    return this.route.queryParamMap.pipe(map(params => {
      let place;
      if (params.get('booking')) {
        place = params.get('booking');
      } else {
        place = params.get('place');
      }
      this.place = JSON.parse(place);
      this.bookingType = params.get('type');

      if (this.place.emailAddress === undefined) {
        this.placeEmailAdress = this.place.place.emailAddress;
      } else {
        this.placeEmailAdress = this.place.emailAddress;
      }

      if(this.bookingType == EVENTPAGETYPES.editBooking) {
        this.attendees = this.place.attendees
      }
    }));
  }

  private initDateTimeSelect() {
    switch (this.bookingType) {
      case EVENTPAGETYPES.scheduledBooking:
        this.searchRequest = this.searchService.searchState$.getValue().searchRequest;
        this.title = this.translateService.instant('scheduledBooking');
        break;
      case EVENTPAGETYPES.editBooking:
        this.subject = this.place.subject;
        this.searchRequest = {
          endTimeUTC: this.place.endTime,
          startTimeUTC: this.place.startTime,
          localEndTimeValue: moment(this.place.endTime).toISOString(true),
          localStartTimeValue: moment(this.place.startTime).toISOString(true),
        };
        this.reminderMinutesBeforeStart = this.place.reminderMinutesBeforeStart
        this.title = this.translateService.instant('editBooking');
        break;
      default:
        this.searchRequest = undefined;
        this.title = this.translateService.instant('quickBooking');
        break;
    }
  }

  changeSubject() {
    const eventRequest = this.mapEventRequest();
    this.checkIfEdited(eventRequest)
  }

  setDefaultReminderMinutes(initialValue: any, listValue: any): boolean {
    return initialValue && listValue ? initialValue == listValue : initialValue == listValue;
  }

  changeReminder(event) {
    this.reminderMinutesBeforeStart = event.target.value;
  }

  updateSearchRequestAndCheckAvailabillity(value) {
    this.searchService.checkAvailabillityRequest$.next(true);
    this.searchRequest = value;
    const eventRequest: EventRequest = this.mapEventRequest();
    this.checkIfEdited(eventRequest)
    this.checkAvailability(eventRequest).pipe(finalize(() => this.searchService.checkAvailabillityRequest$.next(false))).subscribe();
  }

  updateIsBooking(value){
    this.isQuickBooking = value;
  }

  private mapEventRequest(): EventRequest {

    let eventRequest: EventRequest = {
      placeEmailAddress: this.placeEmailAdress,
      subject: this.subject ? this.subject : 'HXA ' + this.title,
      startTime: this.searchRequest.startTimeUTC,
      endTime: this.searchRequest.endTimeUTC,
      reminderMinutesBeforeStart: this.reminderMinutesBeforeStart
    };

    if (this.bookingType == EVENTPAGETYPES.scheduledBooking || this.bookingType == EVENTPAGETYPES.editBooking) {
      eventRequest.attendees = this.attendees;
    }
    return eventRequest;
  }

  private checkAvailability(eventRequest: EventRequest) {
    return this.apiService.checkPlaceAvailability(this.placeEmailAdress, eventRequest.startTime, eventRequest.endTime)
      .pipe(
        map(res => {
          this.searchService.isBookable$.next(res)
          return res;
        }));
  }

  doBookNewEvent() {
    this.eventSubmitted = true;
    const eventRequest: EventRequest = this.mapEventRequest();
    this.apiService.checkUserLicense().subscribe(isValid => {
      if (isValid) {
        this.checkAvailabilityAndCreateEvent(eventRequest).subscribe();
      } else {
        this.noUserLicenseFoundHandler();
      }
    }); 
  }

  private checkAvailabilityAndCreateEvent(eventRequest: EventRequest) {
    this.searchService.checkAvailabillityRequest$.next(true);
    
    return this.checkAvailability(eventRequest).pipe(
      switchMap(res => {
        if (res) {
          return this.apiService.createCalendarEvent(eventRequest).pipe(
            map(() => {
              this.openToastIfSuccess();
            }),
            catchError(() => {
              this.notCreated = true;
              return of();
            }),
            finalize(() => this.searchService.checkAvailabillityRequest$.next(false))
          );
        } else {
          this.searchService.isBookable$.next(false);
          return of(false);
        }
      })
    );
  }

  doEditBooking() {
    this.eventSubmitted = true;
    const eventRequest: EventRequest = this.mapEventRequest();
    this.apiService.checkUserLicense().subscribe(isValid => {
      if (isValid) {
        this.checkAvailibilityAndEditBooking(eventRequest);
      } else {
        this.noUserLicenseFoundHandler();
      }
    });
  }

  private checkAvailibilityAndEditBooking(eventRequest: EventRequest) {
    this.searchService.checkAvailabillityRequest$.next(true);
    return this.checkAvailability(eventRequest).pipe(
      switchMap(res => {
        if (res) {
          return this.editBooking(eventRequest);
        } else {
          return this.proceedEditingAction(eventRequest);
        }
      })).subscribe({ next: () => {
        //this.router.navigate(['tabs/bookings'], { replaceUrl: true });
        this.openToastIfSuccess();
      } });
  }

  private editBooking(eventRequest: EventRequest) {
    const calenderEvent = this.place as CalendarEvent;
    return this.apiService.editCalendarEvent(calenderEvent.id, eventRequest).pipe(
      map(placeEvent => {
        // need not to be remapped now
        //calenderEvent.subject = placeEvent.subject;
        //calenderEvent.startTime = placeEvent.startTime;
        //calenderEvent.endTime = placeEvent.endTime;
      }),
      catchError(() => {
        this.notCreated = true;
        return of();
      }),
      finalize(() => {
        this.searchService.checkAvailabillityRequest$.next(false);
        this.eventSubmitted = false;
      }));
  }

  async proceedEditingAction(eventRequest: EventRequest) {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('placeNotAvailable'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('sendAnyway'),
          role: 'destructive'
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    if (result.role === 'destructive') {
      return this.editBooking(eventRequest).pipe(take(1)).subscribe();
    }
    this.eventSubmitted = false;
    this.searchService.checkAvailabillityRequest$.next(false);
    return of();
  }

  async doDeleteBooking() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('deleteBookingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('deleteBooking'),
          role: 'destructive'
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.deleteEvent().subscribe(isDeleted => {
        if (isDeleted) {
          this.router.navigate(['/tabs/bookings'], { replaceUrl: true });
        }
      });
    }
  }

  private deleteEvent() {
    return this.apiService.deleteCalendarEvent((this.place as CalendarEvent).id).pipe(
      map(isDeleted => isDeleted),
      take(1)
    );
  }

  async openToastIfSuccess() {

    let day = moment(this.searchRequest.localStartTimeValue).locale(this.locale).format('L')
    if (day == moment().locale(this.locale).format('L')) {
      day = ''
    } else {
      day += ' '
    }

    const message = this.title + ' ' + day + this.translateService.instant('eventCreatedToastOne')
      + ' ' + moment(this.searchRequest.localStartTimeValue).format('HH:mm') + ' '
      + this.translateService.instant('eventCreatedToastTwo') + ' '
      + moment(this.searchRequest.localEndTimeValue).format('HH:mm') + ' '
      + this.translateService.instant('eventCreatedToastThree');

    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: 'bottom'
    });

    await toast.present();
    const isClosed = await toast.onDidDismiss();
    if (isClosed) {
      this.searchService.resetDefaultSearchState();
      this.router.navigate(['tabs/bookings'], { replaceUrl: true });
    }
  }

  checkIfEdited(eventRequest: EventRequest) {
    if (this.bookingType == EVENTPAGETYPES.editBooking) {
      if (_.isEqual(this.place.startTime, eventRequest.startTime) && _.isEqual(this.place.endTime, eventRequest.endTime) && _.isEqual(this.place.subject, eventRequest.subject)) {
        this.isEdited = false;
      } else {
        this.isEdited = true;
      }

    }

  }

  ngOnDestroy(): void {
    this.searchService.isBookable$.next(true);
    this.subject = null;
    this.notCreated = false;
    this.searchService.invalidBookingRequest$.next(false);
    this.place = null;
    this.searchRequest = null;
    this.isEdited = false;
  }



  async openAttendeesModal() {

    const modal = await this.modalController.create({
      component: AttendeesModalComponent,
      componentProps: {
        finalChoosenAttendees: this.attendees,
        bookingType: this.bookingType,
        searchRequest: this.searchRequest
      }
    });
    modal.present();
  }

  initAutoGrow() {    
    this.isAutoGrow = true;
  }

  logout() {
    if(this.platform.is('capacitor')) {
      const authOption: AuthOptions = {
        urlHandler: async (authUrl) => {
          await Browser.open({ url: authUrl });
        }
      };
      this.oidcSecurityService.logoff(undefined, authOption);
    } else {
      this.oidcSecurityService.logoff()
    }
  }

  async noUserLicenseFoundHandler() {
    const noUserFoundToast = await this.toastController.create({
      message: this.translateService.instant('noLicenseToast'),
      duration: 5000,
      color: 'danger'
    });
    noUserFoundToast.present();
    noUserFoundToast.onDidDismiss().finally(()=> {
      this.logout();
     });
  }
}
