import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideSearch: boolean

  constructor(private router: Router) {}

  ngOnInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     if( (event.url.includes('home') || event.urlAfterRedirects.includes('home')) || (event.url.includes('favorite') || event.urlAfterRedirects.includes('favorite') ) ) {
    //       this.hideSearch = false;
    //     }
    //   } else {
    //     this.hideSearch = true
    //   }
    // })
  }

}
