import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store";

import { catchError, delay, EMPTY, finalize, map, Observable, of, pipe, switchMap, take, tap } from "rxjs";
import { Place, SearchRequest } from "src/app/interfaces/interfaces";

import { ApiService } from "../../services/api-services/api.service";


export interface AvailablePlacesState {
    roomsPlacesList: Place[];
    roomsPlacesListError: string;
    roomsPlacesListLoading: boolean;
    type: string;
}




@Injectable()
export class AvailablePlacesStore extends ComponentStore<AvailablePlacesState> {

    readonly filteredList$ = this.select((state) => {
        if (state.type != null) {
            return state.roomsPlacesList.filter(s => s.type == state.type);
        }
        return state.roomsPlacesList;
    });
    readonly roomsPlacesListError$ = this.select((state) => state.roomsPlacesListError);
    readonly roomsPlacesListLoading$ = this.select((state) => state.roomsPlacesListLoading);


    constructor(private apiService: ApiService) {

        super({
            roomsPlacesList: [],
            roomsPlacesListError: null,
            roomsPlacesListLoading: null,
            type: null,
        })

    }

    readonly setList = this.updater((state, payload: Place[]) => ({        
        ...state,
        roomsPlacesList: payload,
    }))

    readonly setLoading = this.updater((state, payload: boolean) => ({
        ...state,
        roomsPlacesListLoading: payload
    }))

    readonly setError = this.updater((state, payload: string) => ({
        ...state,
        roomsPlacesListError: payload
    }))


    readonly loadAvailablePlaces = this.effect<void>(
        pipe(
            tap(() => {
                
                this.setList([]);
                this.setError(null);
                this.setLoading(true)
            }),
            switchMap(() => {
                return this.apiService.getAvailablePlaces().pipe(
                    tap({
                        next: (roomsPlacesList) => this.setList(roomsPlacesList.sort(item=>{
                           return item.isFav == true ? -1 : 1
                        })),
                        error: (roomsPlacesListError) => this.patchState({ roomsPlacesListError })
                    }),
                    catchError(() => EMPTY),
                    finalize(() => { 
                        this.setLoading(false)}),

                )
            })
        )
    );

    readonly searchAvailablePlaces = this.effect<SearchRequest>(
        pipe(
            tap(() => {
                this.setList([]);
                this.setError(null);
                this.setLoading(true)             
            }),
            switchMap(searchRequest => {
                return this.apiService.searchAvailablePlaces(searchRequest).pipe(
                    tap({
                        next: (roomsPlacesList) => {
                            this.setList(roomsPlacesList.sort(item=>{
                            return item.isFav == true ? -1 : 1
                         }));
                         this.setLoading(false);
                        },
                        error: (roomsPlacesListError) => this.patchState({ roomsPlacesListError })
                    }),
                    catchError(() => EMPTY),
                    finalize(() => {})
                )
            })
        )

    )
}


