// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { HttpCancleService } from '../services/httpCancelService/http-cancle.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Preferences } from '@capacitor/preferences';

const TOKEN_HEADER_KEY = 'Authorization'
@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

  isAuthenticated: boolean;
  token;
  
  constructor(
    private router: Router,
    private httpCancelService: HttpCancleService, 
    private oidcSecurityService: OidcSecurityService) 
    {
          this.oidcSecurityService.isAuthenticated$.subscribe(status => {
            this.isAuthenticated = status.isAuthenticated;
          });
          router.events.subscribe(event => {
                // An event triggered at the end of the activation part of the Resolve phase of routing.
                if (event instanceof ActivationEnd) {
                  // Cancel pending calls
                  this.httpCancelService.cancelPendingRequests();
                }
          });
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
      
      let request = req;
      if ( req.url.includes("token") || req.url.includes("jwks") || req.url.includes("i18n") || req.url.includes("openid-configuration")) 
      {
        return next.handle(request);
      }


      if (!this.isAuthenticated) {
        this.RefreshSessionIfRefreshtoken().subscribe(Auth => {
          this.token = Auth?.accessToken;
        })
      }
      else{
        this.oidcSecurityService.getAccessToken().subscribe(t => this.token = t);
      }
      request = this.addTokenHeader(req);

      if (req.url.includes("me") || req.url.includes("userinfo") || req.url.includes("attendees")) 
      {
        return next.handle(request);
      }
      else 
      {
        return next.handle(request).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
      }
    }

    private addTokenHeader(request: HttpRequest<any>) {
      /* for Spring Boot back-end */
      // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  
      /* for Node.js Express back-end */
      return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token) });
    }

    private RefreshSessionIfRefreshtoken() {

      return this.oidcSecurityService.getRefreshToken().pipe(
         switchMap(refreshToken => {   
           if (refreshToken) {
             return this.oidcSecurityService.forceRefreshSession().pipe(
               map( isAuthenticated  => {
                
                 if (isAuthenticated?.isAuthenticated) {
                  return isAuthenticated;
                 }

               })
             );
           }
          //  else{
          //   this.router.navigateByUrl('unauthorized');
          //  }
         })
       )
     }
}