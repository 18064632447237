/* eslint-disable max-len */
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpResponse } from '@capacitor/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import { AttendeeInformation, DATETIMEFORMART, EventRequest, Place, SearchRequest, UserInformation } from 'src/app/interfaces/interfaces';
import { CalendarEvent } from 'src/app/interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { FeedbackService } from '../feedback-service/feedback.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    userInformation$ = new BehaviorSubject<UserInformation>(null);
    constructor(private oidcSecurityService: OidcSecurityService, private http: HttpClient, private injector: Injector) { }

    getPlaceById(id: string): Observable<Place> {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place>(environment.baseUrl + `/api/places/${id}`, { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }


    getAvailablePlaces(): Observable<any> {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place[]>(environment.baseUrl + '/api/places', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    checkPlaceAvailability(email: string, startTime: string, endTime: string): Observable<boolean> {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<boolean>(environment.baseUrl + '/api/places/' + email + '/available?startTime=' + startTime + '&endTime=' + endTime, { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }


    searchAvailablePlaces(searchRequest: SearchRequest) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place[]>(environment.baseUrl + '/api/places/search?startTime=' + searchRequest.startTimeUTC + '&endTime=' + searchRequest.endTimeUTC + '&placesType=' + searchRequest.type, { headers: header }).pipe(
            map(r => r),
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    getMyCalendarEvents() {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<CalendarEvent[]>(environment.baseUrl + '/api/me/events', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    createCalendarEvent(eventRequest: EventRequest): Observable<any> {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + '/api/me/event', eventRequest, { headers: header, observe: 'response' }).pipe(
            tap(response => {
                if (response.status == 201) {
                    this.injector.get(FeedbackService).addBookingCount();
                }
            }),
            catchError(err => {
                throw new Error(err.message);
            }));
    }


    editCalendarEvent(id: string, placeEvent: EventRequest) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.put<EventRequest>(environment.baseUrl + '/api/me/event/' + id, placeEvent, { headers: header }).pipe(
            map(placesEvent => placesEvent),
            catchError(err => {
                throw new Error(err.message);

            })
        );
    }

    deleteCalendarEvent(id: string) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.delete<boolean>(environment.baseUrl + '/api/me/event/' + id, { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        );

    }

    getUserInformation() {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<UserInformation>(environment.baseUrl + '/api/me', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    sendUserFeedback(feedback: any) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + '/api/me/feedback', feedback, { headers: header, observe: 'response' }).pipe(
            tap(response => {
                if (response.status == 201) {
                    this.injector.get(FeedbackService).stopReminder();
                }
            }),
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    getAllAttendeesList() {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<AttendeeInformation[]>(environment.baseUrl + '/api/attendees', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    checkAttendeeAvailability(upn: string, startTime: string, endTime: string): Observable<boolean> {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<boolean>(environment.baseUrl + '/api/attendees/' + upn + '/available?startTime=' + startTime + '&endTime=' + endTime, { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    sendContactForm(form: any) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + '/api/account/contact/send', form, { headers: header, observe: 'response' }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        )
    }

    sendProblemTicket(form: any) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + '/api/support/problem/send', form, { headers: header, observe: 'response' }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        )
    }

    sendIdeaTicket(form: any) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + '/api/support/idea/send', form, { headers: header, observe: 'response' }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        )
    }

    checkUserLicense() {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<any>(environment.baseUrl + '/api/licenses/check', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    getPlaceScheduleById(id: string): Observable<any> {
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place>(environment.baseUrl + `/api/places/${id}/availabletimes`, { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    addPlaceToFavorte(placeUPN: string) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.post<any>(environment.baseUrl + `/api/places/${placeUPN}/favorite/add`, {}, { headers: header, observe: 'response' }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        )
    }

    getFavortePlaces() {
        console.log("api");
        
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<any>(environment.baseUrl + '/api/places/favorite', { headers: header }).pipe(
            catchError(err => {
                throw new Error(err.message);
            })
        )
    }

    searchFavoriteAvailablePlaces(searchRequest: SearchRequest) {

        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place[]>(environment.baseUrl + '/api/places/search/favorite?startTime=' + searchRequest.startTimeUTC + '&endTime=' + searchRequest.endTimeUTC + '&placesType=' + searchRequest.type, { headers: header }).pipe(
            map(r => r),
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    searchAvailablePlacesForSeriesEvent() {

        let AccuranceDays =[];
        AccuranceDays.push(1);
        
        let startDate = moment().utc().format(DATETIMEFORMART)
        let endDate = moment().utc().add(90, "days").add(3, "hours").format(DATETIMEFORMART);
        let startTime = moment().format("HH:MM")
        let endTime = moment().add(1, "hours").format("HH:MM")
        let choosenDate = moment().utc().add(1, "days").format(DATETIMEFORMART)
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place[]>(environment.baseUrl + '/api/places/search/series?startTime=' + startDate+ 
                                                                                        '&endTime=' + endDate + 
                                                                                        '&placesType=all' + 
                                                                                        '&AccuranceDays=' + AccuranceDays + 
                                                                                        '&interval=' + 1 + 
                                                                                        '&accuranceType=daily'+ 
                                                                                        '&choosenDate=' + choosenDate , { headers: header }).pipe(
            map(r => r),
            catchError(err => {
                throw new Error(err.message);
            }));
    }

    searchAvailablePlacesForSeriesEvent2() {

        let AccuranceDays =[];
        AccuranceDays.push(1);
        
        let startDate = moment().utc().format(DATETIMEFORMART)
        let endDate = moment().utc().add(90, "days").add(3, "hours").format(DATETIMEFORMART);
        let startTime = moment().format("HH:MM")
        let endTime = moment().add(1, "hours").format("HH:MM")
        let choosenDate = moment().utc().add(1, "days").format(DATETIMEFORMART)
        const header: HttpHeaders = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=UTF-8')
        return this.http.get<Place[]>(environment.baseUrl + '/api/places/search/series2?startTime=' + startDate+ 
                                                                                        '&endTime=' + endDate + 
                                                                                        '&placesType=all' + 
                                                                                        '&AccuranceDays=' + AccuranceDays + 
                                                                                        '&interval=' + 1 + 
                                                                                        '&accuranceType=daily'+ 
                                                                                        '&choosenDate=' + choosenDate , { headers: header }).pipe(
            map(r => r),
            catchError(err => {
                throw new Error(err.message);
            }));
    }
}

