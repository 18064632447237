import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { App, URLOpenListener, URLOpenListenerEvent } from '@capacitor/app';
import { NavController, Platform } from '@ionic/angular';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Browser, OpenOptions } from '@capacitor/browser';

import { ApiService } from './services/api-services/api.service';
import { SettingsPage } from './settings/settings.page';
import { StatusBar, Style } from '@capacitor/status-bar';
import { FeedbackService } from './services/feedback-service/feedback.service';
import { PublicEventsService } from 'angular-auth-oidc-client';
import { Preferences } from '@capacitor/preferences';
import { finalize, map, of, switchMap } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';

const setStatusBarStyleDark = async () => {
  await StatusBar.setStyle({ style: Style.Dark });
  };

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  

  SettingsComp = SettingsPage
  // public appPages = [
  //    { title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
  //    { title: 'MyProfile', icon: 'people-circle-outline', route: '/my-profile', disabled: false },
  //    { title: 'Help', icon: 'help-circle', route: '/', url: null, disabled: true },
  //    { title: 'Rate Us', icon: 'star', route: '/', disabled: true },
  //    { title: 'Support', icon: 'chatbubbles-sharp', route: '/support', disabled: true },

  //    { title: 'Settings', icon: 'settings', route: '/settings', disabled: true },
  // ];
  public appPages = [
    //{ title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
    { title: 'myProfile', icon: 'people-circle-outline', route: '/my-profile', disabled: false },
    { title: 'settings', icon: 'settings', route: '/settings', disabled: true },
    { title: 'support', icon: 'chatbubbles-sharp', route: '/support', disabled: true },
 ];
  isLoading: boolean;
  isNoUser:boolean;

  constructor(
    private platform: Platform,
    private oidcSecurityService: OidcSecurityService,
    private zone: NgZone,
    private router: Router,
    private navigation: NavController,
    public apiService: ApiService,
    private feedbackService: FeedbackService,
    private eventService: PublicEventsService) {

    this.initializeApp();
    this.eventService
    .registerForEvents()
    .subscribe((value) => {
      if (value.type === 2) {
        Preferences.set({
          key: 'isLoggedin',
          value: (false).toString()
        }) 
      } 
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      if (this.platform.is('android') || this.platform.is('ios')) {
        setStatusBarStyleDark();
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          this.zone.run(() => {
            this.oidcSecurityService.checkAuth(event.url).subscribe( isAuthenticated  => {
              console.log("check auth", isAuthenticated);
              
              Browser.close();
              if (isAuthenticated.isAuthenticated) {
                this.router.navigateByUrl('tabs/home');
              } else {

                // let queryParams = event.url.split("?")[1].split("=")[0];
                let queryParams = event.url.split("?")[1];
                let url = "login?" + queryParams;
                this.router.navigateByUrl(url);
              }
            });
          });
        });
       } else {
        this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated }) => { 
        });
      }
    });

    this.platform.resume.subscribe(result => {
      // this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated }) => {           
      //   if (!isAuthenticated) {
      //     this.RefreshSessionIfRefreshtoken().pipe(finalize(() => this.isLoading = false)).subscribe();
      //   }
      // });
    })
    this.feedbackService.checkBookingCount().subscribe()
  }

  open(route) {
    this.navigation.navigateForward(route)
  }

  async openInappBrowser(url: string) {
    await Browser.open({ url });
  }

  logout() {
    console.log('logout');
    
    if(this.platform.is('capacitor')) {
      const authOption: AuthOptions = {
        urlHandler: async (authUrl) => {
          await Browser.open({ url: authUrl });
        }
      };
      this.oidcSecurityService.logoff(undefined, authOption);
    }
    else {
      this.oidcSecurityService.logoff();
    }
    
  }

  private RefreshSessionIfRefreshtoken() {

    return this.oidcSecurityService.getRefreshToken().pipe(
       switchMap(refreshToken => {
         if (refreshToken) {
           return this.oidcSecurityService.forceRefreshSession().pipe(
             map( isAuthenticated  => {
              
               if (isAuthenticated?.isAuthenticated) {
                this.router.navigateByUrl('tabs/home');
               }
               else{
                Preferences.set({
                  key: 'isLoggedin',
                  value: (true).toString()
                }); 
                this.router.navigateByUrl('unauthorized');
               }
             })
           );
         }
         
         return of(true);      
       })
     )
   }


}

