import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


import { SearchService, SearchState } from './search.service';
import { SearchpopoverComponent } from './searchpopover/searchpopover.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  
})
export class SearchComponent implements OnInit {
  state: SearchState;
  searchPopover: HTMLIonPopoverElement;
  @Input() disableSearch = false
  constructor(private popoverController: PopoverController, private searchService: SearchService) { 

  }

  ngOnInit() {
    this.searchService.searchState$.subscribe(state => {
      this.state = state
    });
  }

  async openSearch(event: Event) {

    if(this.searchPopover != undefined) {
      return
    }

    this.searchPopover = await this.popoverController.create({
      component: SearchpopoverComponent,
      event: event,
      cssClass: 'search-popover',
      reference: 'trigger',
      side: 'bottom',
      alignment: 'end',
      mode: 'md',
      componentProps: {initalSearchRequest: this.searchService.searchState$.getValue().searchRequest ? this.searchService.searchState$.getValue().searchRequest : null}
    })

    this.searchService.searchState$.next(this.searchService.searchRequestState)
    await this.searchPopover.present().then();
    this.searchPopover.isOpen = true;
    await this.searchPopover.onDidDismiss().then(request => {
      
      this.searchPopover = undefined
      if(request.role == 'apply') {
        this.searchService.applySearch(request.data);
      }
      if(request.role == 'reset') {
        this.searchService.resetDefaultSearchState();
      }
      if (request.role == 'backdrop') {
        if(this.searchService.searchState$.getValue().searchRequest != null) {
          this.searchService.applySearch(this.searchService.searchState$.getValue().searchRequest)
            
        } else {
          this.searchService.resetDefaultSearchState();
        }
      }
    })
   
  }




}
