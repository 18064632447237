import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { SearchService } from '../search/search.service';
import { AvailablePlacesStore } from './available-places.store';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';  
import { ApiService } from 'src/app/services/api-services/api.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-available-places',
  templateUrl: './available-places.component.html',
  styleUrls: ['./available-places.component.scss'],
})
export class AvailablePlacesComponent implements OnInit, AfterContentInit {
  isFav=false;
  roomsSpacesList$ = this.availablePlacesStore.filteredList$;
  roomsSpacesListLoading$ = this.availablePlacesStore.roomsPlacesListLoading$;
  roomsSpacesListError$ = this.availablePlacesStore.roomsPlacesListError$;
  isLoading = false;
  constructor(private availablePlacesStore: AvailablePlacesStore,
    private router: Router, 
    private searchService: SearchService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private apiService: ApiService) {

      this.roomsSpacesList$.subscribe(list => {
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          if (!element.isAvailable) {
            this.placeNotAvaliableHandler();
            break;
          }
        }
      })
 }

  
  ngOnInit() {
    console.log(this.roomsSpacesList$)
  }

  toggleFav(place) {
    this.isLoading = true;
    place.isFav = !place.isFav;
    this.apiService.addPlaceToFavorte(place.emailAddress).pipe(finalize(()=> this.isLoading = false)).subscribe();
  }

  ngAfterContentInit() {

  }

  eventButton(place){
    let type = EVENTPAGETYPES.quickBooking;
    place= JSON.stringify(place);
    if(this.searchService.searchState$.getValue().searchRequest != null) {
      type = EVENTPAGETYPES.scheduledBooking;
    }
    const nav: NavigationExtras = {
      queryParams: {type, place},
      replaceUrl: true,
    };
    this.router.navigate(['event'], nav );
  }

  async placeNotAvaliableHandler() {
    const placeNotAvailableToast = await this.toastController.create({
      message: this.translateService.instant('placesNotAvailable'),
      duration: 5000
    });
    placeNotAvailableToast.present();
  }

}
