import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApiService } from '../services/api-services/api.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {

  constructor(private navigation: NavController,private apiSerivce:ApiService) { }

  ngOnInit() {
    this.apiSerivce.searchAvailablePlacesForSeriesEvent2().subscribe();
  }

  open(route) {
    this.navigation.navigateForward(route)
  }
}
