import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, PopoverController } from '@ionic/angular';

import * as moment from 'moment';
import { DATETIMEFORMART, SearchRequest } from 'src/app/interfaces/interfaces';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { AvailablePlacesStore } from 'src/app/shared/available-places/available-places.store';
import { SearchService } from '../search.service';
@Component({
  selector: 'app-searchpopover',
  templateUrl: './searchpopover.component.html',
  styleUrls: ['./searchpopover.component.scss'],
  providers: [AvailablePlacesStore]
})
export class SearchpopoverComponent implements OnInit {

  @ViewChild('endConfirm') endConfirm: IonDatetime;
  resourceTypesList = [
    { type: 'all', name: 'all' },
    { type: 'room', name: 'room' },
    { type: 'workspace', name: 'workspace' },
  ];
  selectedResourceType = 'all';

  minimalStartTime;
  

  minimalEndTime;
  searchRequest: SearchRequest;
  disableApply: boolean = false;
  searchtState;
  initalSearchRequest: SearchRequest;
  constructor(private popoverController: PopoverController, private searchService: SearchService, public translate: LanguageService) {
    
    let minutesToSubtract = 0;
    
    if (moment().minutes() < 30) {
      minutesToSubtract = moment().minutes();
    } else {
      minutesToSubtract = moment().minutes() - 30;
    }
    this.searchRequest = {
      startTimeUTC: moment().subtract(minutesToSubtract, 'minutes').utc().format(DATETIMEFORMART),
      endTimeUTC: moment().subtract(minutesToSubtract, 'minutes').utc().add(1, 'hours').format(DATETIMEFORMART),
      type: this.selectedResourceType,
      localStartTimeValue: moment().subtract(minutesToSubtract, 'minutes').format(DATETIMEFORMART),
      localEndTimeValue: moment().subtract(minutesToSubtract, 'minutes').add(1, 'hours').format(DATETIMEFORMART),
    }
    this.setSearchRequest();
  }

  ngOnInit() {

    if(this.initalSearchRequest != null) {
      this.searchRequest = {
        startTimeUTC:  this.initalSearchRequest.startTimeUTC,
        endTimeUTC:  this.initalSearchRequest.endTimeUTC,
        type: this.initalSearchRequest.type,
        localStartTimeValue: this.initalSearchRequest.localStartTimeValue,
        localEndTimeValue: this.initalSearchRequest.localEndTimeValue
      }
    }

   }

  filterRoomsSpacesList(event) {
    let type = event.target.value
    if (type == '') {
      type = null;
    }
    this.searchRequest.type = type;
  }

  private setSearchRequest() {
    if (this.searchService.searchState$.getValue().searchRequest != null) {
      this.searchRequest = this.searchService.searchState$.getValue().searchRequest;
      this.selectedResourceType = this.searchService.searchState$.getValue().searchRequest.type;
    }
    this.minimalStartTime = this.searchRequest.localStartTimeValue;
    this.minimalEndTime = moment(this.searchRequest.localStartTimeValue).add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
  }

  changeStartTime(event) {
    this.searchRequest.startTimeUTC = moment(event.target.value).utc().format(DATETIMEFORMART);
    this.searchRequest.localStartTimeValue = moment(event.target.value).format(DATETIMEFORMART);
    this.searchRequest.endTimeUTC = moment(event.target.value).add(1, 'hours').format(DATETIMEFORMART);
    this.searchRequest.localEndTimeValue = moment(event.target.value).add(1, 'hours').format(DATETIMEFORMART);
  }

  changeEndTime(event) {
    this.searchRequest.endTimeUTC = moment(event.target.value).utc().format(DATETIMEFORMART);
    this.searchRequest.localEndTimeValue = moment(event.target.value).format(DATETIMEFORMART);
    if (this.searchRequest.endTimeUTC <= this.searchRequest.startTimeUTC) {
      this.disableApply = true;
    } else {
      this.disableApply = false;
    }

  }

  applySearch() {
    this.popoverController.dismiss(this.searchRequest, 'apply')
  }

  resetSearch() {
    this.popoverController.dismiss(null, 'reset')

  }

}


