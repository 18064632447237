import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { AttendeeInformation, CalendarEvent } from 'src/app/interfaces/interfaces';
import { AllPeopleService } from 'src/app/services/all-people-service/all-people.service';
import { ApiService } from 'src/app/services/api-services/api.service';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { checkIsNextDay } from 'src/app/utils/date-time-utils';




@Component({
  selector: 'app-my-bookings-item',
  templateUrl: './my-bookings-item.component.html',
  styleUrls: ['./my-bookings-item.component.scss'],
})
export class MyBookingsItemComponent implements OnInit {
  @Input() calendarEvent : CalendarEvent
  @ViewChild('popover') popover;

  public locale;
  public isNextDay = false;
  acceptedAttendees: AttendeeInformation[] = [];
  declinedAttendees: AttendeeInformation[] = [];
  pendingAttendees: AttendeeInformation[] = [];

  isPopoverOpen: boolean = false;

  constructor(private languageService: LanguageService, private router: Router, public actionSheetController: ActionSheetController,  private translateService: TranslateService, private apiService: ApiService, private allPeopleService: AllPeopleService) { 
    this.locale = this.languageService.selectedLanguage$.getValue().value
  }

  ngOnInit() {
    this.isNextDay = checkIsNextDay(this.calendarEvent.startTime, this.calendarEvent.endTime);
    this.allPeopleService.personsListLoading$.subscribe(loading => {
      if(!loading){
        this.calendarEvent.attendees = this.allPeopleService.extendAttendeeInformation(this.calendarEvent.attendees);
        this.splitAttendessByResponse();
      }
    })
  }



  editBooking(booking) {
    let type = EVENTPAGETYPES.editBooking
    booking= JSON.stringify(booking)

    const nav: NavigationExtras = {
      queryParams: {type, booking},
      replaceUrl: true,
    }
    this.router.navigate(['event'], nav );
  }

  async doDeleteBooking(event) {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService. instant('deleteBookingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('deleteBooking'),
          role: 'destructive'
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.deleteEvent(event).subscribe(isDeleted => {
        if (isDeleted) {
          this.router.navigate(['/tabs/home'], { replaceUrl: true });
        }
      });
    }
  }

  private deleteEvent(event) {
    return this.apiService.deleteCalendarEvent((event as CalendarEvent).id).pipe(
      map(isDeleted => isDeleted),
      take(1)
    );
  }

  splitAttendessByResponse(){
    this.acceptedAttendees = this.calendarEvent.attendees.filter(item=>{
      return item.response.toLowerCase() == "accepted"
    })
    this.declinedAttendees = this.calendarEvent.attendees.filter(item=>{
      return item.response.toLowerCase() == "declined"
    })
    this.pendingAttendees = this.calendarEvent.attendees.filter(item=>{
      return item.response.toLowerCase() == "none" || item.response.toLowerCase() == "tentativelyaccepted" || item.response.toLowerCase() == "notresponded"
    })
  }

  presentPopover(e: Event, ) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }


  triggerIonIcon(id){
    let root = document.getElementById(id);
    root.click();
  }
}
