import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/languageservice/language.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {


  constructor(public languageService: LanguageService) { 

  }

  ngOnInit() {}

  compare(e1: any, e2: any): boolean {
    
    
    return e1 && e2 ? e1.value == e2.value : e1 == e2;
  }

  changeLanguage(event) {
    
    this.languageService.selectLanguage(event.target.value)
  }
}
