import { Component, Input, OnInit, Optional } from '@angular/core';
import { ActionSheetController, IonRouterOutlet, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EVENTPAGETYPES } from 'src/app/event/event.page';


@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss'],
})
export class EventHeaderComponent implements OnInit {
  @Input() bookingType: string
  @Input() isEdited: boolean = false;
  constructor(
    private navController: NavController,
    private actionSheetController: ActionSheetController,
    @Optional() private routerOutlet: IonRouterOutlet,
    private translateService: TranslateService) { }


  ngOnInit() { }


  async goBack() {

    if (this.bookingType == EVENTPAGETYPES.editBooking) {
      if (this.isEdited) {
        this.goBookings()
      } else {
        this.navController.navigateBack("/tabs/bookings", { replaceUrl: true })
      }
    } else {
      if (this.routerOutlet.canGoBack()) {
        this.goBackToPrevious();
      }else{
        this.goHome();
      }
      
    }
  }


  async goHome() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('navigationInfo'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('continueEditing'),
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
        {
          text: this.translateService.instant('cancelChanges'),
          role: 'destructive',
          data: {
            action: 'continue',
          },
        },
      ]

    });

    await actionSheet.present();
    const condition = await actionSheet.onWillDismiss();


    if (condition.role == 'destructive') {
      this.navController.navigateBack("/tabs/home", { replaceUrl: true })
    }
  }

  async goBackToPrevious() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('navigationInfo'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('continueEditing'),
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
        {
          text: this.translateService.instant('cancelChanges'),
          role: 'destructive',
          data: {
            action: 'continue',
          },
        },
      ]

    });

    await actionSheet.present();
    const condition = await actionSheet.onWillDismiss();


    if (condition.role == 'destructive') {
      this.navController.back();
    }
  }

  async goBookings() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('navigationInfo'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('continueEditing'),
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
        {
          text: this.translateService.instant('cancelChanges'),
          role: 'destructive',
          data: {
            action: 'continue',
          },
        },
      ]

    });

    await actionSheet.present();
    const condition = await actionSheet.onWillDismiss();

    if (condition.role == 'destructive') {
      this.navController.navigateBack("/tabs/bookings", { replaceUrl: true })
    }

  }
}
