import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent } from 'src/app/interfaces/interfaces';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { checkIsNextDay } from 'src/app/utils/date-time-utils';
@Component({
  selector: 'app-place-location-info',
  templateUrl: './place-location-info.component.html',
  styleUrls: ['./place-location-info.component.scss'],
})
export class PlaceLocationInfoComponent implements OnInit {
  @Input() inputPlace: any
  place: CalendarEvent
  locale: string;
  @Input() showTimes = false;
  isNextDay: boolean;
  constructor(private language: LanguageService) { 
    this.locale = this.language.selectedLanguage$.getValue().value;
  }

  ngOnInit() {
    if(this.inputPlace.place != undefined) {
      this.place = this.inputPlace
      this.isNextDay = checkIsNextDay(this.place.startTime, this.place.endTime);


    } else {
      this.place = {
        id: this.inputPlace.id,
        place: {
          address: this.inputPlace.address,
          displayName: this.inputPlace.displayName,
          type: this.inputPlace.type
        },
        startTime: null,
        endTime: null,
        organizer: null,
        subject: null,
        resourceStatus: null,
        attendees : null
      };

    }
    
  }
}
